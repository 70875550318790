/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../components/layout';
import ScrollToDoc from '../components/ScrollToDoc'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  next: {
    minHeight: 2000,
  }
});

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  }
    this.handleScrollClick = this.handleScrollClick.bind(this)
  }

  handleScrollClick(scrollId) {
    this.setState({
      scrollId: scrollId
    })
  }

  render() {
    const { classes } = this.props;
    const { scrollId } = this.state;

    return (
      <Layout
        className={classes.root}
        location={this.props.location}
        // handler: this.handleScrollClick, id: "some-id" (defaults to hero-sub)
        onScrollClick={{ handler: this.handleScrollClick }}
        >
        {scrollId && <ScrollToDoc doc={{title: {slug: scrollId}}} />}
      </Layout>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index)
